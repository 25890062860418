/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const EngagementAssessment = () => {
  // Assessment dimensions and questions
  const assessmentSections = {
    work_satisfaction: {
      title: "Work Satisfaction & Purpose",
      description: "Evaluate satisfaction with daily work and sense of purpose",
      questions: [
        "I find my work meaningful and purposeful",
        "I have opportunities to use my strengths every day",
        "My work challenges me in a positive way",
        "I can see how my work contributes to organizational goals",
        "I feel a sense of accomplishment in my work"
      ]
    },
    leadership_trust: {
      title: "Leadership & Trust",
      description: "Assess trust in leadership and organizational direction",
      questions: [
        "I trust the leadership team's decisions and direction",
        "Leadership communicates transparently about important issues",
        "I feel comfortable sharing feedback with leadership",
        "Leadership shows genuine interest in employee wellbeing",
        "I understand and believe in the organization's vision"
      ]
    },
    growth_development: {
      title: "Growth & Development",
      description: "Measure opportunities for professional growth",
      questions: [
        "I have clear opportunities for career growth",
        "I receive regular feedback about my performance",
        "I have access to learning and development resources",
        "My manager supports my professional development",
        "I can see a clear career path in this organization"
      ]
    },
    team_relationships: {
      title: "Team & Relationships",
      description: "Evaluate team dynamics and workplace relationships",
      questions: [
        "I feel like a valued member of my team",
        "There is good collaboration within my team",
        "I receive support from my colleagues when needed",
        "Different opinions and perspectives are respected",
        "I have positive relationships at work"
      ]
    },
    resources_support: {
      title: "Resources & Support",
      description: "Assess access to necessary tools and support",
      questions: [
        "I have the resources I need to do my job effectively",
        "My workload is manageable and sustainable",
        "I have a good work-life balance",
        "The workplace environment supports my productivity",
        "I receive adequate support to manage work-related stress"
      ]
    },
    recognition_rewards: {
      title: "Recognition & Rewards",
      description: "Evaluate recognition and compensation satisfaction",
      questions: [
        "My contributions are recognized and valued",
        "I receive fair compensation for my work",
        "The benefits package meets my needs",
        "There are clear criteria for advancement",
        "Good performance is rewarded appropriately"
      ]
    }
  }

  // Response scale
  const responseScale = {
    5: "Strongly Agree",
    4: "Agree",
    3: "Neutral",
    2: "Disagree",
    1: "Strongly Disagree"
  }

  // Recommendation thresholds
  const thresholds = {
    critical: 2.5,
    concern: 3.5,
    good: 4.2
  }

  // State management
  const [assessmentData, setAssessmentData] = useState({
    department: "",
    teamSize: "",
    tenure: "",
    responses: {},
    submitted: false
  })

  // Handle demographic input changes
  const handleInputChange = (field, value) => {
    setAssessmentData(prev => ({
      ...prev,
      [field]: value
    }))
  }

  // Handle question responses
  const handleResponseChange = (section, question, value) => {
    setAssessmentData(prev => ({
      ...prev,
      responses: {
        ...prev.responses,
        [section]: {
          ...prev.responses[section],
          [question]: Number(value)
        }
      }
    }))
  }

  // Calculate section score
  const calculateSectionScore = (section) => {
    const sectionResponses = assessmentData.responses[section]
    if (!sectionResponses) return 0
    const values = Object.values(sectionResponses).map(Number)
    if (values.length === 0) return 0
    return values.reduce((a, b) => a + b, 0) / values.length
  }

  // Calculate overall engagement score
  const calculateOverallScore = () => {
    const sectionScores = Object.keys(assessmentSections).map(calculateSectionScore)
    const validScores = sectionScores.filter(score => score > 0)
    if (validScores.length === 0) return 0
    return validScores.reduce((a, b) => a + b, 0) / validScores.length
  }

  // Generate recommendations based on scores
  const generateRecommendations = () => {
    const recommendations = {
      critical: [],
      improvement: [],
      maintain: []
    }

    for (const [section, data] of Object.entries(assessmentSections)) {
      const score = calculateSectionScore(section)
      
      if (score <= thresholds.critical) {
        recommendations.critical.push({
          id: `${section}-critical`,
          area: data.title,
          score: score,
          actions: getActionItems(section, 'critical')
        })
      } else if (score <= thresholds.concern) {
        recommendations.improvement.push({
          id: `${section}-improvement`,
          area: data.title,
          score: score,
          actions: getActionItems(section, 'improvement')
        })
      } else {
        recommendations.maintain.push({
          id: `${section}-maintain`,
          area: data.title,
          score: score,
          actions: getActionItems(section, 'maintain')
        })
      }
    }

    return recommendations
  }

  // Get specific action items based on section and level
  const getActionItems = (section, level) => {
    const actionItems = {
      work_satisfaction: {
        critical: [
          "Conduct job role reviews to better align tasks with employee strengths",
          "Implement regular check-ins to discuss work meaning and purpose",
          "Create clear connections between individual work and company goals"
        ],
        improvement: [
          "Enhance job enrichment opportunities",
          "Provide more autonomy in work processes",
          "Increase visibility of impact through success stories"
        ],
        maintain: [
          "Continue celebrating meaningful achievements",
          "Share success stories across the organization",
          "Maintain clear communication of organizational impact"
        ]
      },
      leadership_trust: {
        critical: [
          "Establish regular transparent communication channels",
          "Implement anonymous feedback mechanisms",
          "Create leadership accessibility programs"
        ],
        improvement: [
          "Increase frequency of leadership updates",
          "Enhance two-way communication opportunities",
          "Develop leadership visibility initiatives"
        ],
        maintain: [
          "Continue open communication practices",
          "Maintain regular feedback sessions",
          "Sustain leadership accessibility"
        ]
      },
      growth_development: {
        critical: [
          "Create clear career development paths",
          "Implement structured training programs",
          "Establish mentorship opportunities"
        ],
        improvement: [
          "Enhance learning and development resources",
          "Increase development budget allocation",
          "Create more cross-training opportunities"
        ],
        maintain: [
          "Continue supporting professional development",
          "Maintain learning resource availability",
          "Sustain growth opportunity communications"
        ]
      },
      team_relationships: {
        critical: [
          "Implement team building activities",
          "Create collaboration frameworks",
          "Establish conflict resolution processes"
        ],
        improvement: [
          "Enhance team communication channels",
          "Increase cross-functional collaboration",
          "Develop team recognition programs"
        ],
        maintain: [
          "Continue team building initiatives",
          "Maintain collaborative environment",
          "Sustain positive team dynamics"
        ]
      },
      resources_support: {
        critical: [
          "Conduct resource needs assessment",
          "Implement workload management tools",
          "Establish support systems for stress management"
        ],
        improvement: [
          "Enhance available tools and resources",
          "Optimize work processes",
          "Improve support mechanisms"
        ],
        maintain: [
          "Continue resource availability",
          "Maintain support systems",
          "Sustain work-life balance initiatives"
        ]
      },
      recognition_rewards: {
        critical: [
          "Review compensation structures",
          "Implement recognition programs",
          "Establish clear performance rewards"
        ],
        improvement: [
          "Enhance recognition frequency",
          "Increase reward opportunities",
          "Develop additional benefits options"
        ],
        maintain: [
          "Continue recognition practices",
          "Maintain competitive compensation",
          "Sustain performance reward systems"
        ]
      }
    }

    return actionItems[section][level]
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setAssessmentData(prev => ({
      ...prev,
      submitted: true
    }))
  }

  return (
    <Layout className="engagement-assessment-page">
      <SEO 
        title="Employee Engagement Assessment | Measure and Improve Engagement" 
        description="Evaluate employee engagement levels and get actionable recommendations for improvement."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          Employee Engagement Assessment
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Assessment</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            This comprehensive assessment evaluates employee engagement across six key dimensions. 
            The results will help identify areas of strength and opportunities for improvement.
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Takes approximately 10-15 minutes to complete</li>
            <li>Provides immediate insights and recommendations</li>
            <li>All responses are confidential</li>
            <li>Results can be used for team or organizational planning</li>
          </ul>
        </div>

        {!assessmentData.submitted ? (
          <form onSubmit={handleSubmit}>
            {/* Demographics Section */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Department Information</h2>
              <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
                <div>
                  <label htmlFor="department" sx={{ display: 'block', mb: 2 }}>
                    Department
                  </label>
                  <input
                    type="text"
                    id="department"
                    value={assessmentData.department}
                    onChange={(e) => handleInputChange('department', e.target.value)}
                    placeholder="Enter your department"
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px'
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="teamSize" sx={{ display: 'block', mb: 2 }}>
                    Team Size
                  </label>
                  <select
                    id="teamSize"
                    value={assessmentData.teamSize}
                    onChange={(e) => handleInputChange('teamSize', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px'
                    }}
                  >
                    <option value="">Select team size</option>
                    <option value="1-5">1-5 employees</option>
                    <option value="6-15">6-15 employees</option>
                    <option value="16-30">16-30 employees</option>
                    <option value="31-50">31-50 employees</option>
                    <option value="50+">50+ employees</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="tenure" sx={{ display: 'block', mb: 2 }}>
                    Time in Current Role
                  </label>
                  <select
                    id="tenure"
                    value={assessmentData.tenure}
                    onChange={(e) => handleInputChange('tenure', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px'
                    }}
                  >
                    <option value="">Select tenure</option>
                    <option value="0-1">Less than 1 year</option>
                    <option value="1-3">1-3 years</option>
                    <option value="3-5">3-5 years</option>
                    <option value="5-10">5-10 years</option>
                    <option value="10+">10+ years</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Assessment Sections */}
            {Object.entries(assessmentSections).map(([sectionKey, section]) => (
              <div key={sectionKey} sx={{ 
                bg: 'white', 
                p: 4, 
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                mb: 4
              }}>
                <h2 sx={{ fontSize: '1.5rem', mb: 2, color: 'primary' }}>{section.title}</h2>
                <p sx={{ fontSize: '1rem', mb: 4, color: 'gray.7' }}>{section.description}</p>
                
                {section.questions.map((question) => (
                  <div key={`${sectionKey}-${question.substring(0, 20)}`} sx={{ mb: 4 }}>
                    <label htmlFor={`${sectionKey}-${question.substring(0, 20)}`} sx={{ display: 'block', mb: 2 }}>
                      {question}
                    </label>
                    <select
                      id={`${sectionKey}-${question.substring(0, 20)}`}
                      value={assessmentData.responses[sectionKey]?.[question] || ""}
                      onChange={(e) => handleResponseChange(sectionKey, question, e.target.value)}
                      required
                      sx={{
                        width: '100%',
                        p: 2,
                        border: '1px solid',
                        borderColor: 'gray.3',
                        borderRadius: '4px'
                      }}
                    >
                      <option value="">Select response</option>
                      {Object.entries(responseScale).map(([value, label]) => (
                        <option key={value} value={value}>{label}</option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            ))}

            {/* Submit Button */}
            <div sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              mb: 4
            }}>
              <button
                type="submit"
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'primary',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'primaryDark'
                  }
                }}
              >
                Submit Assessment
              </button>
            </div>
          </form>
        ) : (
          <>
            {/* Results Section */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Engagement Assessment Results</h2>
              
              {/* Overall Score */}
              <div sx={{ 
                textAlign: 'center',
                p: 4,
                bg: 'gray.1',
                borderRadius: '8px',
                mb: 4
              }}>
                <h3 sx={{ fontSize: '1.2rem', mb: 2 }}>Overall Engagement Score</h3>
                <p sx={{ 
                  fontSize: '2.5rem', 
                  fontWeight: 'bold',
                  color: 'primary'
                }}>
                  {calculateOverallScore().toFixed(2)}/5.00
                </p>
              </div>

              {/* Section Scores */}
              <div sx={{ 
                display: 'grid', 
                gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
                gap: 4,
                mb: 4
              }}>
                {Object.entries(assessmentSections).map(([section, data]) => (
                  <div key={section} sx={{
                    p: 3,
                    border: '1px solid',
                    borderColor: 'gray.2',
                    borderRadius: '4px'
                  }}>
                    <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>{data.title}</h4>
                    <p sx={{ 
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      color: calculateSectionScore(section) <= thresholds.critical ? 'red' :
                             calculateSectionScore(section) <= thresholds.concern ? 'orange' : 'green'
                    }}>
                      {calculateSectionScore(section).toFixed(2)}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Recommendations Section */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Recommendations</h2>
              
              {/* Critical Areas */}
              {generateRecommendations().critical.length > 0 && (
                <div sx={{ mb: 4 }}>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'red',
                    mb: 3
                  }}>
                    Priority Action Areas
                  </h3>
                  {generateRecommendations().critical.map((item) => (
                    <div key={item.id} sx={{ mb: 4 }}>
                      <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>{item.area} ({item.score.toFixed(2)})</h4>
                      <ul sx={{ 
                        listStyle: 'disc',
                        pl: 4,
                        '& li': { mb: 2 }
                      }}>
                        {item.actions.map((action) => (
                          <li key={`${item.id}-${action}`}>{action}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}

              {/* Improvement Areas */}
              {generateRecommendations().improvement.length > 0 && (
                <div sx={{ mb: 4 }}>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'orange',
                    mb: 3
                  }}>
                    Areas for Improvement
                  </h3>
                  {generateRecommendations().improvement.map((item) => (
                    <div key={item.id} sx={{ mb: 4 }}>
                      <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>{item.area} ({item.score.toFixed(2)})</h4>
                      <ul sx={{ 
                        listStyle: 'disc',
                        pl: 4,
                        '& li': { mb: 2 }
                      }}>
                        {item.actions.map((action) => (
                          <li key={`${item.id}-${action}`}>{action}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}

              {/* Strengths */}
              {generateRecommendations().maintain.length > 0 && (
                <div>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'green',
                    mb: 3
                  }}>
                    Areas of Strength
                  </h3>
                  {generateRecommendations().maintain.map((item) => (
                    <div key={item.id} sx={{ mb: 4 }}>
                      <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>{item.area} ({item.score.toFixed(2)})</h4>
                      <ul sx={{ 
                        listStyle: 'disc',
                        pl: 4,
                        '& li': { mb: 2 }
                      }}>
                        {item.actions.map((action) => (
                          <li key={`${item.id}-${action}`}>{action}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div sx={{ 
              display: 'flex', 
              gap: 3,
              justifyContent: 'flex-end',
              mb: 4
            }}>
              <button
                type="button"
                onClick={() => window.print()}
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'primary',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'primaryDark'
                  }
                }}
              >
                Print Results
              </button>
              <button
                type="button"
                onClick={() => setAssessmentData(prev => ({ ...prev, submitted: false }))}
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'white',
                  color: 'primary',
                  border: '1px solid',
                  borderColor: 'primary',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'gray.1'
                  }
                }}
              >
                Start New Assessment
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default EngagementAssessment 